import { useRouter } from 'next/router'
import { RefObject, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { z } from 'zod'

type Size = {
  width: number | undefined
  height: number | undefined
}

export const TAILWIND_BREAKPOINTS = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
}

export const useWindowSize = (): Size => {
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined,
  })

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}

export const useIsMobile = (breakpoint = TAILWIND_BREAKPOINTS.sm): boolean => {
  const { width } = useWindowSize()
  return !!width && width < breakpoint
}

export const useIsOverflow = <T extends HTMLElement>(ref: RefObject<T>): boolean => {
  const [isOverflow, setIsOverflow] = useState(false)

  useLayoutEffect(() => {
    const element = ref.current
    if (!element) return

    const checkOverflow = () => {
      const hasOverflow =
        element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth

      setIsOverflow(hasOverflow)
    }

    checkOverflow()

    if (window.ResizeObserver !== undefined) {
      const observer = new ResizeObserver(checkOverflow)
      observer.observe(element)

      return () => observer.disconnect()
    }

    return
  }, [ref])

  return isOverflow
}

export const useImpersonate = () => {
  const { query } = useRouter()
  const { impersonate } = z.object({ impersonate: z.optional(z.string()) }).parse(query)

  return { impersonate }
}
