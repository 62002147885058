import classNames from 'classnames'
import { ReactNode } from 'react'

export const H1 = ({
  children,
  extraClassName = '',
}: {
  children: ReactNode
  extraClassName?: string
}) => (
  <h1 className={classNames('text-3xl lg:text-4xl font-bold font-comfortaa', extraClassName)}>
    {children}
  </h1>
)

export const H2 = ({
  children,
  extraClassName = '',
}: {
  children: ReactNode
  extraClassName?: string
}) => (
  <h2
    className={classNames(
      'text-2xl lg:text-3xl font-light font-comfortaa text-slate-900 dark:text-slate-50',
      extraClassName,
    )}
  >
    {children}
  </h2>
)

export const H3 = ({
  children,
  extraClassName = '',
}: {
  children: ReactNode
  extraClassName?: string
}) => (
  <h3
    className={classNames(
      'text-lg lg:text-xl font-semibold font-comfortaa text-slate-700 dark:text-slate-50',
      extraClassName,
    )}
  >
    {children}
  </h3>
)

export const H4 = ({
  children,
  extraClassName = '',
}: {
  children: ReactNode
  extraClassName?: string
}) => <h4 className={classNames('font-comfortaa', extraClassName)}>{children}</h4>
