import { useTheme } from 'next-themes'
import { RiMoonFill, RiSunFill } from 'react-icons/ri'

import { Toggle } from '@elendi/ui-foundations'

export const ThemeChanger = () => {
  const { setTheme, resolvedTheme } = useTheme()

  return (
    <Toggle
      leftLabel={{ key: 'light', render: <RiSunFill /> }}
      rightLabel={{ key: 'dark', render: <RiMoonFill /> }}
      onChange={() => setTheme(resolvedTheme === 'dark' ? 'light' : 'dark')}
      value={resolvedTheme}
    />
  )
}
