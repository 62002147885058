import { AxiosResponse } from 'axios'
import { NextApiRequest } from 'next'
import superjson from 'superjson'

import { ObjectId } from '@elendi/util-objectid'

export const SUPERJSON_API_KEY_NAME = '$superjsonData'
export type APIResult = {
  success: true
  [SUPERJSON_API_KEY_NAME]: string
}

export const readAxiosResponse = async <T>(
  axiosPromise: Promise<AxiosResponse<APIResult, any>>,
): Promise<T> => {
  superjson.registerCustom<ObjectId, string>(
    {
      isApplicable: (v): v is ObjectId => v instanceof ObjectId,
      serialize: (v) => v.toHexString(),
      deserialize: (v) => new ObjectId(v),
    },
    'objectid',
  )

  const { data } = await axiosPromise
  return superjson.parse<T>(data[SUPERJSON_API_KEY_NAME])
}

export const readSuperjsonPayload = <T>(request: NextApiRequest): T => {
  const { body } = request
  if (!(SUPERJSON_API_KEY_NAME in body))
    throw new InvalidSuperJsonPayload(`${SUPERJSON_API_KEY_NAME} key expected in payload`)

  superjson.registerCustom<ObjectId, string>(
    {
      isApplicable: (v): v is ObjectId => v instanceof ObjectId,
      serialize: (v) => v.toHexString(),
      deserialize: (v) => new ObjectId(v),
    },
    'objectid',
  )

  return superjson.parse<T>(body[SUPERJSON_API_KEY_NAME])
}

export const getSuccessAPIResponse = <T>(data: T) => {
  superjson.registerCustom<ObjectId, string>(
    {
      isApplicable: (v): v is ObjectId => v instanceof ObjectId,
      serialize: (v) => v.toHexString(),
      deserialize: (v) => new ObjectId(v),
    },
    'objectid',
  )

  return {
    success: true,
    [SUPERJSON_API_KEY_NAME]: superjson.stringify(data),
  }
}

export const getSuperjsonPayload = <T>(data: T) => {
  superjson.registerCustom<ObjectId, string>(
    {
      isApplicable: (v): v is ObjectId => v instanceof ObjectId,
      serialize: (v) => v.toHexString(),
      deserialize: (v) => new ObjectId(v),
    },
    'objectid',
  )

  return {
    [SUPERJSON_API_KEY_NAME]: superjson.stringify(data),
  }
}

export class InvalidSuperJsonPayload extends Error {
  constructor(msg: string) {
    super(msg)
    Object.setPrototypeOf(this, InvalidSuperJsonPayload.prototype)
  }
}
