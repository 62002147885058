import axios from 'axios'
import useSWR from 'swr'

import { API } from '@elendi/util-endpoints'
import { useImpersonate } from '@elendi/util-hooks'
import { AllTeamMember, UseUserInfosApi } from '@elendi/util-types'

import { APIResult, readAxiosResponse } from '@elendi/feature-superjson-api'

export const useUserInfos = <T extends AllTeamMember>() => {
  const { impersonate } = useImpersonate()

  const getUserInfos = (url: string, impersonated: string | undefined) =>
    readAxiosResponse<UseUserInfosApi<T>>(
      axios.get<APIResult>(url, { params: { impersonate: impersonated } }),
    )

  const { data, error, isLoading, mutate } = useSWR(
    [API.user.getUserInfos, impersonate],
    ([endpoint, impersonate]) => getUserInfos(endpoint, impersonate),
  )

  return {
    userInfos: data?.userInfos,
    displayEmail: data?.displayEmail,
    isLoading,
    isError: error,
    mutate,
  }
}
