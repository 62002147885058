import classNames from 'classnames'
import dynamic from 'next/dynamic'
import { ReactNode } from 'react'

const Switch = dynamic(async () => (await import('@headlessui/react')).Switch, {
  ssr: false,
})
const Group = dynamic(async () => (await import('@headlessui/react')).Switch.Group, {
  ssr: false,
})
const Label = dynamic(async () => (await import('@headlessui/react')).Switch.Label, {
  ssr: false,
})

export const Toggle = ({
  onChange,
  leftLabel,
  rightLabel,
  value,
  orange = false,
  hasImmutableColorSwitch = false,
  disable = false,
}: {
  onChange: () => void
  leftLabel: { key: string; render: ReactNode }
  rightLabel: { key: string; render: ReactNode }
  value: string | undefined
  orange?: boolean
  hasImmutableColorSwitch?: boolean
  disable?: boolean
}) => {
  const state = !value || value === leftLabel.key ? false : true

  return (
    <Group>
      <div className='flex items-center gap-2 select-none'>
        <Label className={classNames('hover:cursor-pointer', disable && 'hover:cursor-default')}>
          {leftLabel.render}
        </Label>

        <Switch
          checked={state}
          onChange={() => {
            if (!disable) onChange()
          }}
          className={classNames(
            hasImmutableColorSwitch
              ? 'bg-slate-200 dark:bg-slate-500'
              : state
                ? orange
                  ? 'bg-orange-600'
                  : 'bg-blue-600'
                : 'bg-slate-200 dark:bg-slate-500',
            'relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2',
            orange ? 'focus:ring-orange-600' : 'focus:ring-blue-600',
            disable && 'hover:cursor-default',
          )}
        >
          <span
            className={`${
              state ? 'translate-x-6' : 'translate-x-1'
            } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
          />
        </Switch>

        <Label className={classNames('hover:cursor-pointer', disable && 'hover:cursor-default')}>
          {rightLabel.render}
        </Label>
      </div>
    </Group>
  )
}
