import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { useCookie } from 'react-use'

import { Toggle } from '@elendi/ui-foundations'

export const LanguageChanger = () => {
  const { lang } = useTranslation()
  const { pathname, asPath, query, push } = useRouter()
  const [_, setCookie] = useCookie('NEXT_LOCALE')
  const setLocaleCookie = (locale: string | undefined) => {
    setCookie(locale ?? 'en')
  }

  return (
    <Toggle
      leftLabel={{ key: 'en', render: <p className='font-semibold'>EN</p> }}
      rightLabel={{ key: 'fr', render: <p className='font-semibold'>FR</p> }}
      onChange={() => {
        const locale = lang === 'fr' ? 'en' : 'fr'
        setLocaleCookie(locale)
        push({ pathname, query }, asPath, { locale })
      }}
      value={lang}
      hasImmutableColorSwitch
    />
  )
}
