import useTranslation from 'next-translate/useTranslation'
import Head from 'next/head'
import { useRouter } from 'next/router'

export const PageTitleHead = ({ title }: { title: string }) => {
  const fullTitle = title ? `elendi - ${title}` : 'elendi'
  const { lang } = useTranslation()
  const router = useRouter()
  return (
    <Head>
      <title>{fullTitle}</title>

      <meta property='og:title' content={title} key='ogTitle' />
      <meta property='og:locale' content={lang} key='ogLocale' />
      <meta property='og:type' content={'website'} key='ogType' />
      <meta property='og:siteName' content={'elendi'} key='ogSiteName' />
      <meta property='og:url' content={router.asPath} />
    </Head>
  )
}
